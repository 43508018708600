import React, { useState, useEffect } from 'react';
import Input from '../../input/input'
import Checkbox from '../../checkbox/checkbox'
import Button from '../../button/button'
import axios from 'axios'
import {useDispatch, useSelector} from 'react-redux'
import actions from '../../../store/actions/index'
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';



let timer = null;
const WaitForClick = () => {
    const dispatch = useDispatch();
    const response = useSelector(state => state.uiReducer.newButtonResponse);

    useEffect(() => {
        timer = setInterval(function(){
            axios.get(`/button/active/${response.data.id}`)
            .then(function (response) {
                if(response.data === 1) {
                    clearInterval(timer);
                    dispatch(actions.uiActions.setNewButtonState(3));
                }
            })
            .catch(function (error) {
            });
        }, 1000);
      },[]);

    return (
        <React.StrictMode>
            <DialogContent> 
                <Typography variant="body" color="textPrimary" component="p">
                    Bitte einmal den Kopf betätigen um ihn zu aktivieren.                
                </Typography>
            </DialogContent>
        </React.StrictMode>
    );
}

export default WaitForClick;