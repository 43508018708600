import React, { useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'

import AdminUserList from '../adminUserList/adminUserList'
import AppBar from '../appBar/appBar'
import Loader from '../Loader/Loader'
import SignIn from '../SignIn/SignIn';

import NewButton from '../newButton/newButton'

import ButtonList from '../buttonList/buttonList'
import ButtonLogs from '../ButtonLogs/buttonLogs'
import BackDrop from '../backDrop/backDrop'
import ButtonDetail from '../ButtonDetail/buttonDetail'
import SignOut from '../SignOut'
import UserDetail from '../UserDetail/userDetail'


import actions from '../../store/actions/index'
import {useDispatch} from 'react-redux'

import axios from 'axios'

/**
 * Layout Component
 * enthält das Seitenlayout und die Routing funktionalität
 *
 * @version 1.0.0
 * @author [Sebastian Ratajczak]
 */
const layout = React.memo(() => {
  const loaderVisible = useSelector(state => state.uiReducer.loaderVisible);
  const isAuthenticated = useSelector(state => state.userReducer.isAuthenticated);
  const backDropVisible = useSelector(state => state.uiReducer.backDropVisible);
  const dispatch = useDispatch();


  useEffect(() => {
    const authKey = localStorage.getItem('authKey');
    if(authKey === null) return;

    axios.get('auth/me', {
      headers: {
        'Authorization': `bearer ${authKey}`
      }
    })
    .then((res) => {
      dispatch(actions.userActions.setLogin(authKey));
    })


  }, []);

  let routes = (
    <React.Fragment>
      <AppBar />
      <Loader show={loaderVisible}/>
      <Switch>

        <Route path="/admin/user" component={AdminUserList} />
        <Route path="/user" component={UserDetail} />
        <Route path="/admin" component={AdminUserList} />
        <Route path="/button/new" component={NewButton} />
        <Route path="/logs/:buttonId" component={ButtonLogs} />
        <Route path="/logout" component={SignOut} />
        <Route path="/:buttonId" component={ButtonDetail} />
        <Route path="/" component={ButtonList} />
      </Switch>
    </React.Fragment>
  );

  if(!isAuthenticated) {
    routes = (<SignIn />)
  }
  
  return (
    <React.Fragment>
      <BackDrop show={backDropVisible} />
      {routes}
    </React.Fragment>
  )
})

export default layout;