import React, { useState } from 'react';
import { Link } from 'react-router-dom'

import classes from './appBar.module.css'

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCogs, faThList, faUser } from '@fortawesome/free-solid-svg-icons'


const list = React.memo((props) => {          
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);

  }
  const handleClose = () => {
    setAnchorEl(null);

  }

    return (
      <nav className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              texXmo Button Config
            </Typography>

            <Button
              color="inherit"
              className={classes.button}
              startIcon={<FontAwesomeIcon icon={faThList} />}
              component={Link}
              to="/"
            >
              Buttons
            </Button>

            <Button
              color="inherit"
              className={classes.button}
              startIcon={<FontAwesomeIcon icon={faCogs} />}
              component={Link}
              to="/admin"
            >
              Administration
            </Button>

            <Button
              color="inherit"
              className={classes.button}
              startIcon={<FontAwesomeIcon icon={faUser} />}
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
            >
              Nutzer
            </Button>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem 
                  onClick={handleClose}
                  component={Link}
                  to="/user">
                    Profil
                </MenuItem>
                <MenuItem 
                  onClick={handleClose}
                  component={Link}
                  to="/logout">
                    Log Out
                </MenuItem>
              </Menu>
        </Toolbar>
      </AppBar>
    </nav>
    )
}) 


export default list;