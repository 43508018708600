import React, { useState, useEffect } from 'react';
import Input from '../../input/input'
import Checkbox from '../../checkbox/checkbox'
import axios from 'axios'
import {useDispatch, useSelector} from 'react-redux'
import actions from '../../../store/actions/index'
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Switch, FormControlLabel } from '@material-ui/core';


 const NewButtonForm = () => {
    const [name, setName] = useState('');
    const [options, setOptions] = useState({
        enableConfig: true,
        enableWifi: false,
        enableSta: false,
        enableCounter: false,
        enableWPS: false,
    });
    const dispatch = useDispatch();

    const sendNewButtonData = () => {
        const dataToSend = {
            name: name,
            enableConfig: options.enableConfig,
            enableWifi: options.enableWifi,
            enableStaConfig: options.enableSta,
            enableCounter: options.enableCounter,
            enableWPS: options.enableWPS,
            get1: ''
        };
        
        dispatch(actions.uiActions.showLoader());
        axios.post('/button', dataToSend)
          .then(function (response) {
              
            dispatch(actions.uiActions.setNewButtonResponse(response));
            dispatch(actions.uiActions.setNewButtonState(1));
          })
          .catch(function (error) {
              
          });
    }

    const onCancelClicked = () => {
        dispatch(actions.uiActions.hideModal());
    }
    const inputChangedHandler = (event, name) => {
        if(name === 'name') setName(event.target.value);
    }
    const optionsChangedHandler = (event, name) => {
        switch(name) {
            case 'config':
                setOptions({
                    ...options,
                    enableConfig: event.target.checked});
                break;
            case 'sta':
                setOptions({
                    ...options,
                    enableSta: event.target.checked});
                break;
            case 'wifi':
                setOptions({
                    ...options,
                    enableWifi: event.target.checked});
                break;
            case 'counter':
                setOptions({
                    ...options,
                    enableCounter: event.target.checked});
                break;
            case 'wps':
                setOptions({
                    ...options,
                    enableWPS: event.target.checked});
                break;
            default:
        }
    }
    return (
        <React.StrictMode>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="Name"
                    label="Name"
                    type="text"
                    value={name}
                    onChange={(event) => inputChangedHandler(event, 'name')}
                    fullWidth
                />
                <FormControlLabel
                    control={
                    <Switch 
                        checked={options.enableConfig}
                        onChange={(event) => optionsChangedHandler(event, 'config')}
                    />
                    }
                    label="Config"
                /><br />
                <FormControlLabel
                    control={
                    <Switch 
                        checked={options.enableWifi}
                        onChange={(event) => optionsChangedHandler(event, 'wifi')}
                    />
                    }
                    label="Wifi"
                /><br />
                <FormControlLabel
                    control={
                    <Switch 
                        checked={options.enableSta}
                        onChange={(event) => optionsChangedHandler(event, 'sta')}
                    />
                    }
                    label="STA"
                /><br />
                <FormControlLabel
                    control={
                    <Switch 
                        checked={options.enableCounter}
                        onChange={(event) => optionsChangedHandler(event, 'counter')}
                    />
                    }
                    label="Counter"
                /><br />
                <FormControlLabel
                    control={
                    <Switch 
                        checked={options.enableWPS}
                        onChange={(event) => optionsChangedHandler(event, 'wps')}
                    />
                    }
                    label="WPS"
                />
                
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancelClicked} color="primary">
                    Abbrechen
                </Button>
                <Button onClick={sendNewButtonData} color="primary" autoFocus>
                    Weiter
                </Button>
            </DialogActions>
        </React.StrictMode>
    );
}

export default NewButtonForm;