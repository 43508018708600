import React, { useState, useEffect } from 'react';
import Input from '../../input/input'
import Checkbox from '../../checkbox/checkbox'
import axios from 'axios'
import {useDispatch, useSelector} from 'react-redux'
import actions from '../../../store/actions/index'

import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';

import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'



const SetupButton = () => {
    const [url, setUrl] = useState('');

    const response = useSelector(state => state.uiReducer.newButtonResponse);
    const dispatch = useDispatch();
    
    const handleNext = () => {
        dispatch(actions.uiActions.setNewButtonState(2));
    }

    const inputChangeHandler = event => {
        setUrl(event)
    }


    const onConfigClicked = () => {
          
          const testURL = `${url}/api?get1=${axios.defaults.baseURL+"/button/activate/"+response.data.id}+"%3Fmac={mac}%26ssid={ssid}%26psk={psk}`;
        const myInit = {
            method: 'POST',
            mode: 'no-cors',
        };

        const myRequest = new Request(testURL, myInit);
        
        fetch(myRequest).then(function(response) {
            return response;
        }).then(function(response) {
        }).catch(function(e){
        });
    }

    const onCloseClicked = () => {
        axios.delete(`/button/${response.data.id}`)
        .then((res) => {
            dispatch(actions.uiActions.hideModal())
            dispatch(actions.uiActions.setNewButtonState(0));
            
        })
        .catch((err) => {
            dispatch(actions.uiActions.hideModal())
            dispatch(actions.uiActions.setNewButtonState(0));
            
        })
    }

    return (
        <React.StrictMode>
            <DialogContent> 
                <List dense>
                    <ListItem>
                        <ListItemText primary="1. Öffnen sie das Konfigurationsinterface ihres Buttons(siehe Anleitung)." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="2. Setzten sie die folgende Domain" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={axios.defaults.baseURL+"/button/activate/"+response.data.id+"?mac={mac}&ssid={ssid}"} />
                    </ListItem>
                </List>
                <Divider />
                <List dense>
                    <ListItem>
                        <ListItemText primary="1. Wenn sich der Button im Netzwerk beffindet kann diese Automatisierung genutzt werdn (experimentel)" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="2. Den Button in den Configmodus schalten(gedrückt halten bis grün bestätigt)" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="3. Die IP oder den Domainnamen in das Textfeld eingeben und mit dem Button bestätigen." />
                    </ListItem>
                </List>
                <TextField
                        margin="dense"
                        id="ip"
                        label="IP"
                        type="text"
                        value={url}
                        onChange={(event) => setUrl(event.target.value)}
                        fullWidth
                    />
                      
                <Button onClick={onConfigClicked} variant="contained">Konfigurieren</Button>      
            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseClicked} color="primary">
                    Abbrechen
                </Button>
                <Button onClick={handleNext} color="primary" autoFocus>
                    Weiter
                </Button>
            </DialogActions>
        </React.StrictMode>
    );
}

export default SetupButton;