import React from 'react'
import classes from './backDrop.module.css';

/**
 * Backdrop
 * enthält das Backdrop
 *
 * @version 1.0.0
 * @author [Sebastian Ratajczak]
 */
const backDrop = (props) => {

    return props.show ? <div className={classes.backDrop} /> : null;
}
    


export default backDrop;