import React, {useEffect, useState} from 'react';
import classes from './buttonLogs.module.css'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import {useDispatch, useSelector} from 'react-redux'
import actions from '../../store/actions/index'
import Modal from '../modal/modal'
import { Link as RouterLink } from 'react-router-dom';

import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid'
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { faCogs, faThList, faUser } from '@fortawesome/free-solid-svg-icons'

import NewButton from '../newButton/newButton'


const buttonList = React.memo((props) => {

    const { match: { params } } = props;
    const [response, setResponse] = useState(null);


    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };

    const dispatch = useDispatch();
    
    useEffect(() => {
        axios.get(`/button/logs/${params.buttonId}`)
        .then((res) => {
            setResponse(res.data);
        })
        .catch((error) => {
        })
    }, []);

    if(response === null) return (<React.Fragment />)

    const listElements = response.logs.map((element, index) => (
        <ListItem 
            key={element.id}
            button
            selected={selectedIndex === index}
            onClick={(event) => handleListItemClick(event, index)}            
        >
            <ListItemText
                primary={`Action ${element.action}`}
                secondary={element.created_at}
            />
        </ListItem>   
    ))

    let details = <Typography>No Logs availible</Typography>

    if(response.logs.length !== 0) {
        let action = "Update"
        if(response.logs[selectedIndex].action != 4) {
            action = response.logs[selectedIndex].url + " antwortet mit " + response.logs[selectedIndex].status;
        }

        details = (
            <React.Fragment>
                <Typography gutterBottom variant="h5" component="h2">
                    Log Details:
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    ID: {response.logs[selectedIndex].id} 
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    Erstellt am: {response.logs[selectedIndex].created_at} 
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    Aktion: {action} 
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    Battrie: {response.logs[selectedIndex].bat} V ({response.logs[selectedIndex].per}%) 
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    Wlan: {response.logs[selectedIndex].ssid} ({response.logs[selectedIndex].bssid}) 
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    Beacon: {response.logs[selectedIndex].blu} ({response.logs[selectedIndex].blm}) @ {response.logs[selectedIndex].blv/1000}V 
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    Clicks: {response.logs[selectedIndex].cpu} 
                </Typography>
            </React.Fragment>
        )
    }
    

    
    
    return (
        <div className={classes.content}>
        <Typography gutterBottom variant="h5" component="h2">
            Logs zu {response.name}
        </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
            <div className={classes.demo}>
                <List dense={true}>
                    {listElements}                  
                </List>
            </div>
        </Grid>
        <Grid item xs={12} md={6}>
            {details}
        </Grid>
        </Grid>
        </div>
    )
}) 
export default buttonList;