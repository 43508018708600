import axios from 'axios'

const setLogin = (accessToken) => {
    axios.defaults.headers.common = {'Authorization': `bearer ${accessToken}`}
    localStorage.setItem('authKey', accessToken);
    return {
        type: "SETNEWLOGIN",
        accessToken: accessToken
    }
}
const logout = () => {
    axios.defaults.headers.common = {}
    localStorage.setItem('authKey', null);
    return {
        type: "LOGOUT"
    }
}

//export default setLogin;
export default {
    setLogin,
    logout,
}