import React, { useState, useEffect } from 'react';


import classes from './adminUserList.module.css'
import axios from 'axios'

import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Switch, FormControlLabel } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {useDispatch, useSelector} from 'react-redux'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash, faUser, faCogs } from '@fortawesome/free-solid-svg-icons'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const adminUserList = React.memo(() => {

    const [response, setResponse] = useState([]);

    const [openUserModal, setOpenUserModal] = useState(false);
    const [userData, setUserData] = useState({
       email: '',
       flags: [], 
       id: null
    });

    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [deleteData, setDeleteData] = useState({})

    const showModal = useSelector(state => state.uiReducer.modalVisible);
    const dispatch = useDispatch();
    
    const [errors, setErrors] = useState({
        email: null,
    });

    useEffect(() => {
        axios.get('/admin/user')
        .then((res) => {
            setResponse(res.data);
        })
        .catch((error) => {
        })

    }, [openUserModal]);

    const handleUserClickOpen = (id) => {
        if(id === -1) { //Neuer Benutzer
            setUserData({
                email: '',
                flags: [],
                id: null
            })
        }
        else {
            let flags = []
            if(response[id].isActive) flags.push('active');
            if(response[id].isAdmin) flags.push('admin');
            setUserData({
                email: response[id].email,
                flags,
                id: response[id].id,
            })
        }
        setOpenUserModal(true);
    };

    const handleUser = () => {
        const payload = {
            email: userData.email,
            isActive: (userData.flags.indexOf('active') !== -1),
            isAdmin: (userData.flags.indexOf('admin') !== -1),
        }
        
        if(userData.id === null) {
            axios.post('/admin/user', payload)
            .then(res => {
                handleUserClose();
            })
            .catch(err => {
                setErrors({
                    ...errors,
                    ...err.response.data
                })
            })
        }   
        else {
            axios.put(`/admin/user/${userData.id}`, payload)
            .then(res => {
                handleUserClose();
            })
            .catch(err => {
                setErrors({
                    ...errors,
                    ...err.response.data
                })
            })
        }     
    }

    const handleUserClose = () => {
        setOpenUserModal(false);
    };



    const handleClickOpen = (id, name) => {
        setDeleteData({
            id,
            name
        })
        setOpenDeleteModal(true);
    };

    const handleDelete = () => {
        axios.delete(`/admin/user/${deleteData.id}`)
        .then(res => {            
            setResponse(response.filter(el => el.id !== deleteData.id ? el : null))
            setOpenDeleteModal(false);
        })
        .catch(err => {
            setOpenDeleteModal(false);
        })
    }

    const handleClose = () => {
        setOpenDeleteModal(false);
    };

    const userElements = response.map((element, index) => (
        <ListItem key={element.id} dense button>
            <ListItemText primary={element.email} />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="comments">
                  <FontAwesomeIcon icon={faEdit} onClick={() => handleUserClickOpen(index)} />
              </IconButton>
              <IconButton edge="end" aria-label="comments">
                  <FontAwesomeIcon icon={faTrash} onClick={() => handleClickOpen(element.id, element.email)} />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
    ))

    const handleToggle = (value) => () => {
        const currentIndex = userData.flags.indexOf(value);
        const newChecked = [...userData.flags];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setUserData({
            ...userData,
            flags: newChecked
        })
    };


    return (
        <div className={classes.content}>
            <Dialog
                open={openUserModal}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Nutzer {userData.id === null ? "erstellen" : "bearbeiten"} ?</DialogTitle>
                <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="E-Mail"
                    type="email"
                    value={userData.email}
                    onChange={(event) => setUserData({
                        ...userData,
                        email: event.target.value
                    })}
                    fullWidth
                    error={errors.email !== null ? true : false }
                    helperText={errors.email}
                />
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faUser} />
                        </ListItemIcon>
                        <ListItemText id="switch-active" primary="Aktiv" />
                        <ListItemSecondaryAction>
                        <Switch
                            onChange={handleToggle('active')}
                            checked={userData.flags.indexOf('active') !== -1}
                            edge="end"
                            inputProps={{ 'aria-labelledby': 'switch-active' }}
                        />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faCogs} />
                        </ListItemIcon>
                        <ListItemText id="switch-admin" primary="Admin" />
                        <ListItemSecondaryAction>
                        <Switch
                            edge="end"
                            onChange={handleToggle('admin')}
                            checked={userData.flags.indexOf('admin') !== -1}
                            inputProps={{ 'aria-labelledby': 'switch-admin' }}
                        />
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>                
            </DialogContent>
            <DialogActions>
                <Button onClick={handleUserClose} color="primary">
                    Abbrechen
                </Button>
                <Button onClick={handleUser} color="primary" autoFocus>
                    {userData.id === null ? "Erstellen" : "Bearbeiten"}
                </Button>
            </DialogActions>
            </Dialog>

            <Dialog
                open={openDeleteModal}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Nutzer löschen?</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Wollen sie wirklich den Nutzer mit der Mail Adresse {deleteData.name} löschen?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Nein
                </Button>
                <Button onClick={handleDelete} color="primary" autoFocus>
                    Ja
                </Button>
                </DialogActions>
            </Dialog>

            <Card key={1}>
            <CardContent>
                <List className={classes.root}>
                    {userElements}
                </List>
            </CardContent>
            <DialogActions>
                <Button onClick={() => handleUserClickOpen(-1)} color="primary">
                    Neuer Nutzer
                </Button>
            </DialogActions>
        </Card>
    </div>
    )
})


export default adminUserList;