import React, {useEffect, useState} from 'react';
import classes from './buttonList.module.css'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import {useDispatch, useSelector} from 'react-redux'
import actions from '../../store/actions/index'
import Modal from '../modal/modal'
import { Link as RouterLink } from 'react-router-dom';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import NewButton from '../newButton/newButton'


const buttonList = React.memo((props) => {
    const [response, setResponse] = useState([]);

    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [deleteData, setDeleteData] = useState({})

    const showModal = useSelector(state => state.uiReducer.modalVisible);
    const dispatch = useDispatch();
    
    useEffect(() => {
        axios.get('/button/')
        .then((res) => {
            setResponse(res.data);
        })
        .catch((error) => {
        })
    }, [showModal]);

    const handleClickOpen = (id, name) => {
        setDeleteData({
            id,
            name
        })
        setOpenDeleteModal(true);
    };

    const handleDelete = () => {
        axios.delete(`/button/${deleteData.id}`)
        .then(res => {            
            setResponse(response.filter(el => el.id !== deleteData.id ? el : null))
            setOpenDeleteModal(false);
        })
        .catch(err => {
            setOpenDeleteModal(false);
        })
    }
    
    const handleClose = () => {
        setOpenDeleteModal(false);
    };


    const onNewHandler = () => {
        dispatch(actions.uiActions.showModal());
    }
    
    const buttonElements = response.map(element => (
        <Card key={element.id}>
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                    {element.name}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    Aktuellste Konfiguration: {element.newConfig ? "Nein" : "Ja" }
                </Typography>
            </CardContent>
            <CardActions>
                <Button component={RouterLink} to={`/${element.id}`} size="small" color="primary">
                    Ändern
                </Button>
                <Button component={RouterLink} to={`/logs/${element.id}`} size="small" color="primary">
                    Logs
                </Button>
                <Button onClick={() => handleClickOpen(element.id, element.name)} size="small" color="primary">
                    Löschen
                </Button>
            </CardActions>
        </Card>
    ))


    
    
    return (
        <div className={classes.content}>
            <Dialog open={showModal} onClose={handleClose} aria-labelledby="form-dialog-title">
                <NewButton />
            </Dialog>

            <Dialog
                open={openDeleteModal}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Button löschen?</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Wollen sie wirklich den Button {deleteData.name} löschen?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Nein
                </Button>
                <Button onClick={handleDelete} color="primary" autoFocus>
                    Ja
                </Button>
                </DialogActions>
            </Dialog>

            {buttonElements}

            <Card>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        Neuen Button
                    </Typography>
                    <FontAwesomeIcon onClick={onNewHandler} className={classes.icon} icon={faPlus} />
                </CardContent>
            </Card>
        </div>
    )
}) 
export default buttonList;