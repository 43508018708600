import React,{useState} from 'react'
//import React, { useEffect, useState } from 'react'
import axios from 'axios'
import {useDispatch} from 'react-redux'
import actions from '../store/actions/index'
import{useEffect} from 'react'
import userReducer from '../store/reducers/userReducer'
import { Redirect } from "react-router-dom";


function SignOut() {
  const [redirect, setRedirect] = useState(false)
    const dispatch = useDispatch();
    useEffect(() => {

      axios.post('/auth/logout')
      .then(function (response) {
        dispatch(actions.userActions.logout());
        setRedirect(true);
      })
      .catch(function (error) {
        alert("Ausloggen nicht möglich, später erneut versuchen");
      });


    },[]);

    
    return (
      <Redirect to="/" />
    );
}

export default SignOut;

