import React from 'react';
import classes from './blockElement.module.css'

const blockElement = React.memo((props) => {
    
 
    return (
        <div className={classes.block}>
            <h1>{props.title}</h1>
            {props.children}
        </div>
    )
}) 


export default blockElement;