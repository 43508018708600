import React from 'react';
import classes from './input.module.css'

const list = React.memo((props) => {
    
 
    return (
        <div className={classes.input_wrapper}>
            <input value={props.value} onChange={props.change} type={props.type} className={classes.input} required/>
            <span className={classes.input_label}>{props.label}</span>
        </div>
    )
}) 


export default list;