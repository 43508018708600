import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux'

import NewButtonForm from './States/newButtonForm'
import SetupButton from './States/setupButton'
import WaitForClick from './States/waitForClick'
import Finisch from './States/finish'
import { DialogTitle } from '@material-ui/core';




const newButton = React.memo((props) => {
    const addingState = useSelector(state => state.uiReducer.newButtonState);
    let currentStateData = null;
    switch(addingState) {
        case 1:
            currentStateData = <SetupButton />;
            break;
        case 2:
            currentStateData = <WaitForClick />;
            break;
        case 3:
            currentStateData = <Finisch />;
            break;
        default:
            currentStateData = <NewButtonForm />;
            break;
    }
    

    return (
    <React.StrictMode>
        <DialogTitle id="alert-dialog-title">Neuer Button?</DialogTitle>
        {currentStateData}
    </React.StrictMode>
        
    )
}); 

/*
        */


export default newButton;