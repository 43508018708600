import React, { useEffect, useState } from 'react';
import classes from './userDetail.module.css'
import axios from 'axios'
import Input from '../input/input'

import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Switch, FormControlLabel } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';


const buttonDetail = React.memo((props) => {
    const { match: { params } } = props;

    const [info, setInfo] = useState({
        id: null,
    });
    const [email, setMail] = useState('');
    const [pw, setPw] = useState({});
    const [changed, setChanged] = useState({
        general: false,
        password: false,
    })
    const [errors, setErrors] = useState({
        email: null,
        current: null,
        pw:null,
    })
    const [pwDiffrent, setPwDiffrent] = useState(false);

    const [toast, setToast] = React.useState(null);

    
    useEffect(() => {
        axios.get(`/auth/me`)
        .then((res) => {
            setMail(res.data.email)
        })
        .catch((error) => {
        })

    }, []);

    const inputChangedHandler = (event, name) => {
        if(name === 'email') {
            setMail(event.target.value);
            setChanged({...changed, general:true});
        }
        if(name === 'current') {
            setPw({
                ...pw,
                current: event.target.value
            });
            setChanged({...changed, password:true});
        }
        if(name === 'new1') {
            setPw({
                ...pw,
                pw1: event.target.value
            });
            setChanged({...changed, password:true});
        }
        if(name === 'new2') {
            setPw({
                ...pw,
                pw2: event.target.value
            });
            setChanged({...changed, password:true});
        }        
    }
    
    const onChangeHandler = (element) => {
        let payload = null;
        switch(element) {
            case "allgemein":
                payload = {
                    changed:"general",
                    email
                }
                break;
            case "password":
                payload = {
                    changed:"password",
                    current: pw.current,
                    pw: pw.pw1,
                    pw_confirmation: pw.pw2
                }
                break;
            default:
        }
        axios.put(`auth/me`, payload)
        .then((response) => {
            switch(element) {
                case "allgemein":
                    setChanged({...changed, general:false});
                    setToast("Email erfolgreich geändert")
                    

                    break;
                case "password":
                    setChanged({...changed, password:false});
                    setPw({
                        current: '',
                        new1: '',
                        new2: ''
                    });
                    setToast("Passwort erfolgreich geändert");
                    break;
                default:
            }
            setErrors({
                email: null,
                current: null,
                pw:null,
            })
        })
        .catch((error) => {
          
          setErrors(error.response.data);
        });
    }


    const handleClose = () => {
        setToast(null);
    };

    

    return (
        <React.Fragment>
        <Snackbar
            anchorOrigin={{
            vertical: 'top',
            horizontal: 'middle',
            }}
            open={toast === null ? false : true}
            onClose={handleClose}
            autoHideDuration={3000}
            message={toast}
        />            
        <div className={classes.content}>
            <Card key={1}>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        Allgemein
                    </Typography>
                    <TextField
                        margin="dense"
                        id="E-Mail"
                        label="E-Mail"
                        type="text"
                        value={email}
                        onChange={(event) => inputChangedHandler(event, 'email')}
                        helperText={errors.email}
                        error = {errors.email}
                        fullWidth
                    />
                    
                </CardContent>
                {changed.general ? 
                    <CardActions>
                        <Button onClick={() => onChangeHandler("allgemein")} size="small" color="primary">
                            Speichern
                        </Button>
                    </CardActions> : null}
            </Card>

            <Card key={2}>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        Passwort
                    </Typography>
                    <TextField
                        margin="dense"
                        label="Aktuelles Passwort"
                        type="password"
                        value={pw.current}
                        onChange={(event) => inputChangedHandler(event, 'current')}
                        helperText={errors.current}
                        error = {errors.current}
                        fullWidth
                    />
                    <TextField
                        margin="dense"
                        label="Neues Passwort"
                        type="password"
                        value={pw.new1}
                        onChange={(event) => inputChangedHandler(event, 'new1')}
                        helperText={errors.pw}
                        error = {errors.pw}
                        fullWidth
                    />
                    <TextField
                        margin="dense"
                        label="Passwort wiederholen"
                        type="password"
                        value={pw.new2}
                        onChange={(event) => inputChangedHandler(event, 'new2')}
                        fullWidth
                    />
                    
                </CardContent>
                {changed.password ? 
                    <CardActions>
                        <Button disabled={pwDiffrent} onClick={() => onChangeHandler("password")} size="small" color="primary">
                            Speichern
                        </Button>
                    </CardActions> : null}
            </Card>
        </div>
        </React.Fragment>
    )
}) 


export default buttonDetail;
