import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom'
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import axios from 'axios'

import './index.css';
import * as serviceWorker from './serviceWorker';
import reducer from './store/reducers/combineReducers'

import Layout from './Components/Layout/Layout'



axios.defaults.baseURL = 'https://iotbutton.teamprojects.de/api/'



axios.defaults.headers.post['Content-Type'] = 'application/json';

const store = createStore(reducer)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Layout />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
