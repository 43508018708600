import React,{useState} from 'react'
import classes from './SignIn.module.css'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faKey} from '@fortawesome/free-solid-svg-icons'
import actions from '../../store/actions/index'
import {useDispatch} from 'react-redux'
import axios from 'axios'
import Snackbar from '@material-ui/core/Snackbar'

function SignIn () {
    const dispatch = useDispatch();

        const [uname, setUname] = useState("");
        const [pword, setPword] = useState("");
        const [toast, setToast] = React.useState(null);

        const [errors, setErrors] = useState({
            email: null,
            password:null,
        })

    const handleInputs = (event, name) => {
        if(name === 'uname') setUname(event.target.value);
        else if(name === 'pword') setPword(event.target.value);
    }
    const handleClose = () => {
        setToast(null);
    };


    const handleSignIn = () =>{
        
        axios.post('auth/login', {
            email: uname,
            password: pword
          })
          .then(function (response) {
            dispatch(actions.userActions.setLogin(response.data.access_token));
          })
          .catch(function (error) {
            setErrors({
              email: error.response.data,
              password:null,
            })
          });

    };
    const handleSignUp = () =>{
            axios.post('auth/register',{
                email: uname,
                password: pword
            })
            .then(function (response) {
                setToast("Erfolgreich registriert.")
              })
              .catch(function (error) {
                setErrors(error.response.data);
              });
    };

    return(
        <div className={classes.container}>
        <Snackbar
            anchorOrigin={{
            vertical: 'top',
            horizontal: 'middle',
            }}
            open={toast === null ? false : true}
            onClose={handleClose}
            autoHideDuration={3000}
            message={toast}
        />  

            <div className={classes.first}>
                <Typography  className={classes.top} variant="h5"  gutterBottom>
                  Sign In
                </Typography>
                <TextField onChange={(event) => handleInputs(event, 'uname')} label="email" color="primary" type="email" margin="dense" gutterBottom 
                  helperText={errors.email}
                  error = {errors.email}
                  InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={faUser}/>
                        </InputAdornment>
                      ),
                  }}/><br/>

                <TextField onChange={(event) => handleInputs(event, 'pword')} label="password" type="password" margin="dense" gutterBottom
                  helperText={errors.password}
                  error = {errors.password}
                InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FontAwesomeIcon icon={faKey}/>
                      </InputAdornment>
                    ),
                  }}/><br/>

                <Button onClick={handleSignIn}  variant="contained" color="primary" gutterBottom>Sing In</Button> 
                <Button style={{textDecoration:'underline'}} onClick={handleSignUp} size="small" color="primary" >Sign Up</Button> <br/>

            </div>
            <div className={classes.second} />

        </div>
    )
}
export default SignIn;