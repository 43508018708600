import React from 'react';
import classes from './button.module.css'

const button = React.memo((props) => {
    return (
        <a onClick={props.clicked} className={classes.button} href={props.href}>{props.label}</a>
    )
}) 


export default button;