const showBackdrop = () => {
    return {
        type: "SHOWBACKDROP"
    }
}

const hideBackdrop = () => {
    return {
        type: "HIDEBACKDROP"
    }
}

const showLoader = () => {
    return {
        type: "SHOWLOADER"
    }
}

const hideLoader = () => {
    return {
        type: "HIDELOADER"
    }
}
const showModal = () => {
    return {
        type: "SHOWMODAL"
    }
}

const hideModal = () => {
    return {
        type: "HIDEMODAL"
    }
}
const setNewButtonResponse = (response) => {
    return {
        type: "SETNEWBUTTONRESPONSE",
        response: response
    }
}
const setNewButtonState = (state) => {
    return {
        type: "SETNEWBUTTONSTATE",
        state: state
    }
}

export default {
    showBackdrop,
    hideBackdrop,
    showModal,
    hideModal,
    showLoader,
    hideLoader,
    setNewButtonResponse,
    setNewButtonState
}