const initialState = {
    isAuthenticated: false,
    accessToken: null,
}

const userReducer = (state = initialState, action) => {
    switch(action.type) {
        case "SETNEWLOGIN":
            return {
                ...state,
                isAuthenticated: true,
                accessToken: action.accessToken
            }
            case "LOGOUT":
                return {
                    ...state,           
                    isAuthenticated: false,
                    accessToken: null
                }                   
        default:
            return state;
    }
};

export default userReducer;
