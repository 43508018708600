import uiActions from './uiActions'
import userActions from './userActions'

const actions = {
    uiActions,
    userActions
}


export default actions;
