const initialState = {
    loaderVisible: false,
    backDropVisible: false,
    newButtonResponse: null,
    newButtonState: 0,
    modalVisible: false,
}

const uiReducer = (state = initialState, action) => {
    switch(action.type) {
        case "SHOWBACKDROP":
            return {
                ...state,
                backDropVisible: true,
            }
        case "HIDEBACKDROP":
            return {
                ...state,
                backDropVisible: false,
            }
        case "SHOWMODAL":
            return {
                ...state,
                modalVisible: true,
            }
        case "HIDEMODAL":
            return {
                ...state,
                modalVisible: false,
            }
        case "SHOWLOADER":
            return {
                ...state,
                loaderVisible: true,
            }
        case "HIDELOADER":
            return {
                ...state,
                loaderVisible: false,
            }
        case "SETNEWBUTTONSTATE":
            return {
                ...state,
                newButtonState: action.state,
            }
        case "SETNEWBUTTONRESPONSE":
            return {
                ...state,
                newButtonResponse: action.response,
            }
        default:
            return state;
    }
};

export default uiReducer;