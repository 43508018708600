import React, { useState, useEffect } from 'react';
import Input from '../../input/input'
import Checkbox from '../../checkbox/checkbox'
import axios from 'axios'
import {useDispatch, useSelector} from 'react-redux'
import actions from '../../../store/actions/index'
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'



const Finisch = () => {
    const dispatch = useDispatch();
    const handleNext = () => {
        dispatch(actions.uiActions.setNewButtonState(0));
        dispatch(actions.uiActions.hideModal());
    }
    return (
        <React.StrictMode>
            <DialogContent> 
                <Typography variant="body" color="textPrimary" component="p">
                    Fertig. Der Knopf wurde registriert.
                </Typography>                
            </DialogContent>
            <DialogActions>
                <Button onClick={handleNext} color="primary" autoFocus>
                    Fertig
                </Button>
            </DialogActions>
        </React.StrictMode>
    );
}

export default Finisch;