import React, { useEffect, useState } from 'react';
import classes from './buttonDetail.module.css'
import BlockElement from '../blockElement/blockElement'
import axios from 'axios'
import Input from '../input/input'
import Checkbox from '../checkbox/checkbox'
import Action from '../blockElement/action/action'

import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Switch, FormControlLabel } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';


const buttonDetail = React.memo((props) => {
    const { match: { params } } = props;

    const [info, setInfo] = useState({
        id: null,
    });
    const [name, setName] = useState('');
    const [uuidf, setUuidf] = useState('');
    const [basicAuth, setBasicAuth] = useState('');
    const [timeServer, setTimeserver] = useState('');
    const [userJson, setUserJson] = useState('');
    const [get1, setGet1] = useState('');
    const [get2, setGet2] = useState('');
    const [get3, setGet3] = useState('');
    const [status1, setStatus1] = useState('');
    const [status2, setStatus2] = useState('');
    const [status3, setStatus3] = useState('');
    const [threeGet, setThreeGet] = useState(true);
    const [ssid, setSsid] = useState('');
    const [psk, setPsk] = useState('');
    const [changed, setChanged] = useState({
        general: false,
        admin: false,
        gets: false,
        wlan: false
    })

    const [options, setOptions] = useState({
        enableConfig: false,
        enableWifi: false,
        enableStaConfig: false,
        enableCounter: false,
        enableWPS: false,
    });
    

    useEffect(() => {
        axios.get(`/button/${params.buttonId}`)
        .then((res) => {
            
            setOptions({
                enableConfig: res.data.enableConfig,
                enableWifi: res.data.enableWifi,
                enableStaConfig: res.data.enableStaConfig,
                enableCounter: res.data.enableCounter,
                enableWPS: res.data.enableWPS,
            })
            setName(res.data.name);
            setUuidf(res.data.uuidf);
            setBasicAuth(res.data.basicauth);
            setTimeserver(res.data.timeserver);
            setUserJson(res.data.userjson);
            setGet1(res.data.get1);
            setGet2(res.data.get2);
            setGet3(res.data.get3);
            setStatus1(res.data.status1);
            setStatus2(res.data.status2);
            setStatus3(res.data.status3);
            setSsid(res.data.ssid);
            setPsk(res.data.psk);
            setThreeGet(res.data.threeGet);
            setInfo({
                id: res.data.id,
                mac: res.data.mac
            })
        })
        .catch((error) => {
        })

    }, []);

    const inputChangedHandler = (event, name) => {
        if(name === 'name') {
            setName(event.target.value);
            setChanged({...changed, general:true});
        }
        if(name === 'uuidf') {
            setUuidf(event.target.value);
            setChanged({...changed, general:true});
        }
        if(name === 'basicAuth') {
            setBasicAuth(event.target.value);
            setChanged({...changed, general:true});
        }
        if(name === 'timeServer') {
            setTimeserver(event.target.value);
            setChanged({...changed, general:true});
        }
        if(name === 'userJson') {
            setUserJson(event.target.value);
            setChanged({...changed, general:true});
        }
        if(name === 'get1') {
            setGet1(event.target.value);
            setChanged({...changed, gets:true});
        }
        if(name === 'get2') {
            setGet2(event.target.value);
            setChanged({...changed, gets:true});
        }
        if(name === 'get3') {
            setGet3(event.target.value);
            setChanged({...changed, gets:true});
        }
        if(name === 'status1') {
            setStatus1(event.target.value);
            setChanged({...changed, gets:true});
        }
        if(name === 'status2') {
            setStatus2(event.target.value);
            setChanged({...changed, gets:true});
        }
        if(name === 'status3') {
            setStatus3(event.target.value);
            setChanged({...changed, gets:true});
        }
        if(name === 'ssid') {
            setSsid(event.target.value);
            setChanged({...changed, wlan:true});
        }
        if(name === 'psk') {
            setPsk(event.target.value);
            setChanged({...changed, wlan:true});
        }
        if(name === 'threeGet') {
            setThreeGet(event.target.checked);
            setChanged({...changed, gets:true});
        }
    }
    const optionsChangedHandler = (event, name) => {
        switch(name) {
            case 'config':
                setOptions({
                    ...options,
                    enableConfig: event.target.checked});
                break;
            case 'sta':
                setOptions({
                    ...options,
                    enableStaConfig: event.target.checked});
                break;
            case 'wifi':
                setOptions({
                    ...options,
                    enableWifi: event.target.checked});
                break;
            case 'counter':
                setOptions({
                    ...options,
                    enableCounter: event.target.checked});
                break;
            case 'wps':
                setOptions({
                    ...options,
                    enableWPS: event.target.checked});
                break;
            default:
        }
        setChanged({...changed, admin:true});
    }
    const onChangeHandler = (element) => {
        let payload = null;

        switch(element) {
            case "allgemein":
                payload = {
                    name
                }
                break;
            case "admin":
                payload = {
                    enableConfig: options.enableConfig,
                    enableWifi: options.enableWifi,
                    enableStaConfig: options.enableStaConfig,
                    enableCounter: options.enableCounter,
                    enableWPS: options.enableWPS,
                }
                break;
            case "gets":
                payload = {
                    threeGet,
                    get1,
                    get2,
                    get3,
                    status1,
                    status2,
                    status3
                }
                break;
            case "wlan":
                payload = {
                    ssid
                }
                if(psk !== "*****") {
                    payload = {
                        ...payload,
                        password: psk
                    }
                }
                break;
            default:
        }
        axios.put(`/button/${info.id}`, payload)
        .then(function (response) {
            switch(element) {
                case "allgemein":
                    setChanged({...changed, general:false});
                    break;
                case "admin":
                    setChanged({...changed, admin:false});
                    break;
                case "gets":
                    setChanged({...changed, gets:false});
                    break;
                case "wlan":
                    setChanged({...changed, wlan:false});
                    break;
                default:
            }
        })
        .catch(function (error) {
        });
    }

    return (
        <div className={classes.content}>
            <Card key={1}>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        Allgemein
                    </Typography>
                    <TextField
                        margin="dense"
                        id="Name"
                        label="Name"
                        type="text"
                        value={name}
                        onChange={(event) => inputChangedHandler(event, 'name')}
                        fullWidth
                    />
                    <TextField
                        margin="dense"
                        id="UUID Filter"
                        label="UUID Filter"
                        type="text"
                        value={uuidf}
                        onChange={(event) => inputChangedHandler(event, 'uuidf')}
                        fullWidth
                    />
                    <TextField
                        margin="dense"
                        id="BasicAuth"
                        label="Basic Auth"
                        type="text"
                        value={basicAuth}
                        onChange={(event) => inputChangedHandler(event, 'basicAuth')}
                        fullWidth
                    />
                    <TextField
                        margin="dense"
                        id="Timeserver"
                        label="Timeserver"
                        type="text"
                        value={timeServer}
                        onChange={(event) => inputChangedHandler(event, 'timeServer')}
                        fullWidth
                    />
                    <TextField
                        margin="dense"
                        id="BasicAuth"
                        label="User Json"
                        type="text"
                        value={userJson}
                        onChange={(event) => inputChangedHandler(event, 'userJson')}
                        fullWidth
                    />
                    <Typography variant="body2" color="textSecondary" component="p">
                        created_at: "2020-07-23T22:33:56.000000Z"
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        ID: {info.id}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        MAC: {info.mac}
                    </Typography>
                </CardContent>
                {changed.general ? 
                    <CardActions>
                        <Button onClick={() => onChangeHandler("allgemein")} size="small" color="primary">
                            Speichern
                        </Button>
                    </CardActions> : null}
            </Card>

            <Card key={2}>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        Admin
                    </Typography>
                    <FormControlLabel
                        control={
                        <Switch 
                            checked={options.enableConfig}
                            onChange={(event) => optionsChangedHandler(event, 'config')}
                        />
                        }
                        label="Config"
                    /><br />
                    <FormControlLabel
                        control={
                        <Switch 
                            checked={options.enableWifi}
                            onChange={(event) => optionsChangedHandler(event, 'wifi')}
                        />
                        }
                        label="Wifi"
                    /><br />
                    <FormControlLabel
                        control={
                        <Switch 
                            checked={options.enableStaConfig}
                            onChange={(event) => optionsChangedHandler(event, 'sta')}
                        />
                        }
                        label="STA"
                    /><br />
                    <FormControlLabel
                        control={
                        <Switch 
                            checked={options.enableCounter}
                            onChange={(event) => optionsChangedHandler(event, 'counter')}
                        />
                        }
                        label="Counter"
                    /><br />
                    <FormControlLabel
                        control={
                        <Switch 
                            checked={options.enableWPS}
                            onChange={(event) => optionsChangedHandler(event, 'wps')}
                        />
                        }
                        label="WPS"
                    />
                </CardContent>
                {changed.admin ? 
                    <CardActions>
                        <Button onClick={() => onChangeHandler("admin")} size="small" color="primary">
                            Speichern
                        </Button>
                    </CardActions> : null}
            </Card>


            <Card key={3}>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        WLan
                    </Typography>
                    <FormControlLabel
                        control={
                        <Switch 
                            checked={threeGet}
                            onChange={(event) => inputChangedHandler(event, 'threeGet')}
                        />
                        }
                        label="Config"
                    />

                    <Grid container className={classes.root} spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                margin="dense"
                                label="Get 1"
                                type="text"
                                value={get1}
                                onChange={(event) => inputChangedHandler(event, 'get1')}
                                className={classes.input}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                margin="dense"
                                label="Status 1"
                                type="number"
                                value={status1}
                                onChange={(event) => inputChangedHandler(event, 'status1')}
                                className={classes.input}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                margin="dense"
                                label="Get 2"
                                type="text"
                                value={get2}
                                onChange={(event) => inputChangedHandler(event, 'get2')}
                                className={classes.input}
                                disabled={!threeGet}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                margin="dense"
                                label="Status 2"
                                type="number"
                                value={status2}
                                onChange={(event) => inputChangedHandler(event, 'status2')}
                                className={classes.input}
                                disabled={!threeGet}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                margin="dense"
                                label="Get 3"
                                type="text"
                                value={get3}
                                onChange={(event) => inputChangedHandler(event, 'get3')}
                                className={classes.input}
                                disabled={!threeGet}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                margin="dense"
                                label="Status 3"
                                type="number"
                                value={status3}
                                onChange={(event) => inputChangedHandler(event, 'status3')}
                                className={classes.input}
                                disabled={!threeGet}
                            />
                        </Grid>
                    </Grid>

                </CardContent>
                {changed.gets ? 
                    <CardActions>
                        <Button onClick={() => onChangeHandler("gets")} size="small" color="primary">
                            Speichern
                        </Button>
                    </CardActions> : null}
            </Card>


            <Card key={4}>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        WLan
                    </Typography>
                    <TextField
                        margin="dense"
                        id="Name"
                        label="SSID"
                        type="text"
                        value={ssid}
                        onChange={(event) => inputChangedHandler(event, 'ssid')}
                        fullWidth
                    />
                    <TextField
                        margin="dense"
                        id="Name"
                        label="PSK"
                        type="password"
                        value={psk}
                        onChange={(event) => inputChangedHandler(event, 'psk')}
                        fullWidth
                    />
                </CardContent>
                {changed.wlan ? 
                    <CardActions>
                        <Button onClick={() => onChangeHandler("wlan")} size="small" color="primary">
                            Speichern
                        </Button>
                    </CardActions> : null}
            </Card>

        </div>
    )
}) 


export default buttonDetail;
