import React from 'react';
import classes from './checkbox.module.css'

const checkbox = React.memo((props) => {
    
 
    return (
        <div className={classes.switch_wrapper}>
            <span>{props.label}</span>
            <div className={classes.switch}>
                <input checked={props.checked} onChange={props.changed} id={props.label} type="checkbox" className={classes.switch_input} />
                <label for={props.label} className={classes.switch_label}></label>
            </div>
        </div>
    )
}) 


export default checkbox;