import React from 'react';
import classes from './Loader.module.css'

const list = React.memo((props) => {
    const show = props.show ? "block" : "none";
 
    return (
      <div className={classes.loader} style={{display : show}}>
        <div className={classes.bar}></div>
      </div>
    )
}) 


export default list;